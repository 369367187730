import React from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { Features } from './Features';
import { Customers } from './Customers';
import { GETLN_WAITLIST_URL } from '../config';

export const Hero = React.memo(function Hero(): JSX.Element {
  return (
    <Flex flexDir='column' height='100%' w={'100%'}>
      <Flex
        direction='column'
        w={'100%'}
        minH={{
          base: 'calc(100vh - 64px)',
          sm: 'calc(100vh - 64px)',
          md: 'calc(100vh - 100px)',
        }}
        justifyContent={'center'}
        gap={'16'}
      >
        <Flex
          direction='column'
          justifyContent='center'
          alignItems={'center'}
          height={'100%'}
        >
          <Text
            fontSize={{ base: '40px', md: '56px', lg: '72px', xl: '86px' }}
            fontWeight={'600'}
            textAlign={'center'}
            fontFamily={'body'}
            lineHeight={'140%'}
          >
            Payments for Africa
          </Text>
          <Text
            textAlign={'center'}
            mt={'24px'}
            fontFamily={'body'}
            fontSize={{ base: '16px', lg: '18px' }}
          >
            Enterprise grade, secure,{' '}
            <span style={{ color: '#fb931a' }}>lightning</span> fast
            micro-payments, for Africa.
          </Text>

          <Link
            mt='10'
            color={'white'}
            href={GETLN_WAITLIST_URL}
            isExternal
            borderRadius={'8px'}
            bgColor={'#fb931a'}
            fontSize={{ base: '16px', lg: '18px' }}
            fontWeight={'700'}
            padding={'0px 32px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'54px'}
            _hover={{
              textDecor: 'none',
              bgColor: 'white',
              color: '#fb931a',
              border: '2px solid #fb931a',
            }}
          >
            Join Our Waitlist
          </Link>
        </Flex>
      </Flex>
      <Features />
      <Customers />
    </Flex>
  );
});
