import React from 'react';
import { Center, Flex, Heading, Icon, Text } from '@chakra-ui/react';

import { ReactComponent as ExchangeIcon } from '../assets/svgs/exchange.svg';
import { ReactComponent as PaymentIcon } from '../assets/svgs/payment.svg';
import { ReactComponent as IdentityIcon } from '../assets/svgs/identity.svg';

export const Features = React.memo(function Features(): JSX.Element {
  return (
    <Flex
      minH={'100vh'}
      flexDir={'column'}
      gap={{ base: '8', md: '16' }}
      width={'100%'}
      pb={'150px'}
    >
      <Heading
        color={'black'}
        fontSize={{ base: '30px', md: '48px' }}
        fontFamily={'body'}
        textAlign={'center'}
      >
        PRODUCTS
      </Heading>

      <Center>
        <Text
          textAlign={'center'}
          maxW={'720px'}
          mt={'12px'}
          fontFamily={'body'}
          fontSize={{ base: '16px', lg: '18px' }}
        >
          Build payment experiences that delight your customers and keep them
          coming back for more.
        </Text>
      </Center>

      <Flex
        gap={'24px'}
        display={{ base: 'flex', md: 'grid' }}
        flexDir={{ base: 'column' }}
        gridTemplateColumns={{
          md: 'repeat(auto-fit, minmax(290px, 1fr))',
        }}
        alignItems={'center'}
        justifyItems={'center'}
        w={'100%'}
      >
        <FeatureBlock
          title={'Exchange'}
          body={`Quickly swap between Bitcoin and your local currency. We provide you with the best rates, and the fastest transaction speeds.`}
          imgUrl={ExchangeIcon}
        />
        <FeatureBlock
          title={'Payments'}
          body={`Assign unique Bolt11 or Bolt12 payment addresses to your clients so they can receive payments. We never take custody your customer funds.`}
          imgUrl={PaymentIcon}
        />
        <FeatureBlock
          title={'Identity'}
          body={`Onboard your customers to Nostr network so your entire app can interoperate with the Nostr ecosystem. Your customers own their data.`}
          imgUrl={IdentityIcon}
        />
      </Flex>
    </Flex>
  );
});

interface FeatureBlockProps {
  title: string;
  body: string;
  imgUrl: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

export const FeatureBlock = React.memo(function FeatureBlock({
  title,
  body,
  imgUrl,
}: FeatureBlockProps): JSX.Element {
  return (
    <Flex
      border={'1.5px solid #fb931a'}
      h={'400px'}
      w={{ base: '300px', xl: '350px' }}
      rounded={'12px'}
      flexDir={'column'}
    >
      <Flex
        bgColor={'#fb931a'}
        height={'130px'}
        w={'100%'}
        roundedTop={'9px'}
        alignItems={'flex-end'}
        justifyContent={'center'}
      >
        <Center
          bgColor={'white'}
          h={'120px'}
          w={'120px'}
          rounded={'50%'}
          mb={'-60px'}
        >
          <Center
            h={'100px'}
            w={'100px'}
            rounded={'50%'}
            border={'1px solid #fb931a'}
            overflow={'hidden'}
          >
            <Icon as={imgUrl} height={'100%'} width={'100%'} />
          </Center>
        </Center>
      </Flex>
      <Flex flexDir={'column'} gap={'4'} pt={'80.5px'} px={'2'}>
        <Text
          textAlign={'center'}
          fontSize={'24px'}
          letterSpacing={'0.5px'}
          fontFamily={'body'}
          fontWeight={'700'}
        >
          {title}
        </Text>
        <Text textAlign={'center'} fontFamily={'body'}>
          {body}
        </Text>
      </Flex>
    </Flex>
  );
});
