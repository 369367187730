import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Footer, Header, Hero } from './components';

export const Home = React.memo(function Home(): JSX.Element {
  return (
    <Flex
      direction='column'
      align='center'
      minH='100vh'
      overflowX='hidden'
      className='Home'
    >
      <Header />
      <Flex
        flexGrow={1}
        direction='column'
        w={'100%'}
        maxW='1440px'
        px={{ base: '12px', md: '20px', lg: '40px', xl: '100px' }}
      >
        <Hero />
      </Flex>
      <Footer />
    </Flex>
  );
});
