import React from 'react';
import { Flex, Heading, Center, Image, Text, Link } from '@chakra-ui/react';
import { GETLN_WAITLIST_URL } from '../config';

export const Customers = React.memo(function Customers() {
  const companies = [
    { name: 'Bitsacco', logo: 'https://bitsacco.com/apple-touch-icon.png' },
    { name: 'Splice', logo: 'https://splice.africa/logo.svg' },
    { name: 'Tando', logo: 'https://tando.me/img/apple-icon-114x114.png' },
  ];

  return (
    <Flex
      minH={'80vh'}
      flexDir={'column'}
      gap={{ base: '8', md: '16' }}
      width={'100%'}
      pb={'100px'}
    >
      <Heading
        color={'black'}
        fontSize={{ base: '30px', md: '48px' }}
        fontFamily={'body'}
        textAlign={'center'}
      >
        CUSTOMERS
      </Heading>
      <Center>
        <Text fontSize='2xl' mb={10} textAlign={'center'}>
          Businesses using <span style={{ color: '#fb931a' }}>GETLN</span> to
          power best-in-class payment experiences
        </Text>
      </Center>
      <Flex
        wrap='wrap'
        justifyContent='center'
        alignItems='center'
        gap={{ base: '8', md: '16' }}
      >
        {companies.map((company) => (
          <Flex
            key={company.name}
            flexDir={'column'}
            justifyContent='center'
            alignItems='center'
            gap={'2'}
          >
            <Image
              src={company.logo}
              alt={company.name}
              maxW='100px'
              maxH='100px'
            />
            <Text>{company.name}</Text>
          </Flex>
        ))}
      </Flex>
      <Center>
        <Link
          mt='10'
          color={'white'}
          href={GETLN_WAITLIST_URL}
          isExternal
          borderRadius={'8px'}
          bgColor={'#fb931a'}
          fontSize={{ base: '16px', lg: '18px' }}
          fontWeight={'700'}
          padding={'0px 32px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'54px'}
          _hover={{
            textDecor: 'none',
            bgColor: 'white',
            color: '#fb931a',
            border: '2px solid #fb931a',
          }}
        >
          Join Our Waitlist
        </Link>
      </Center>
    </Flex>
  );
});
