import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import bg from '../assets/images/hero-bg.jpg';

export const Footer = React.memo(function Footer() {
  return (
    <Flex
      direction={{ base: 'column-reverse', sm: 'row' }}
      position={'relative'}
      align='center'
      justify='center'
      h='600px'
      w='100vw'
      p={5}
    >
      <Box
        background={`url(${bg}) center/cover no-repeat`}
        filter='blur(2px)'
        opacity={0.8}
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
      <Flex
        direction={{ base: 'column-reverse', sm: 'row' }}
        align='center'
        justify='center'
        h='600px'
        w='100vw'
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1}
      >
        <Text
          fontSize={{ base: '24px', lg: '32px' }}
          fontWeight={'800'}
          textAlign={'center'}
          fontFamily={'body'}
          lineHeight={'140%'}
          color={'#fb931a'}
        >
          © GETLN
        </Text>
      </Flex>
    </Flex>
  );
});
