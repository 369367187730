import React from 'react';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { GETLN_WAITLIST_URL } from '../config';

export const Header = React.memo(function Header(): JSX.Element {
  return (
    <Flex
      justifyContent='space-between'
      maxW='1440px'
      width='100%'
      mr={[2, 4, 6, 10]}
      ml={[2, 4, 6, 10]}
      p={5}
      position='sticky'
      top={0}
      zIndex={1000}
    >
      <Heading size='xl' fontFamily='body' color='#fb931a'>
        GETLN
      </Heading>
      <Link
        color={'white'}
        href={GETLN_WAITLIST_URL}
        isExternal
        borderRadius={'8px'}
        bgColor={'#fb931a'}
        fontSize={{ base: '16px', lg: '18px' }}
        fontWeight={'600'}
        minW={'124px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'54px'}
        _hover={{
          textDecor: 'none',
          bgColor: 'white',
          color: '#fb931a',
          border: '2px solid #fb931a',
        }}
      >
        Waitlist
      </Link>
    </Flex>
  );
});
